const MEDUSA_API_KEY = process.env.NEXT_PUBLIC_MEDUSA_PUBLIC_KEY || "";
const REVALIDATE_WINDOW = process.env.NEXT_PUBLIC_REVALIDATE_WINDOW || 60; // 60 Sekunden

export default async function medusaRequest(
  method: string,
  path = "",
  payload?: {
    query?: Record<string, unknown>;
    body?: Record<string, unknown>;
  },
  requestOptions?: {
    revalidate?: number;
    cache?: RequestCache;
  },
) {
  const IS_SERVER = typeof window === "undefined";
  // set the Medusa URL based on the environment
  let medusaEndpoint = IS_SERVER ? process.env.MEDUSA_BACKEND_URL_INTERNAL : process.env.NEXT_PUBLIC_MEDUSA_BACKEND_URL;

  // if we are on the server, but the MEDUSA_BACKEND_URL_INTERNAL is not set or is empty, we use the NEXT_PUBLIC_MEDUSA_BACKEND_URL
  if (IS_SERVER && (!medusaEndpoint || medusaEndpoint === "")) {
    medusaEndpoint = process.env.NEXT_PUBLIC_MEDUSA_BACKEND_URL || "http://localhost:9000";
  }

  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      "x-publishable-key": MEDUSA_API_KEY,
    },
    next: {
      revalidate: !requestOptions?.cache ? (requestOptions?.revalidate ?? parseInt(REVALIDATE_WINDOW.toString())) : undefined,
      tags: ["medusa_request"],
    },
    cache: requestOptions?.cache,
  };

  if (payload?.body) {
    options.body = JSON.stringify(payload.body);
  }

  if (payload?.query) {
    const params = objectToURLSearchParams(payload.query!).toString();
    path = `${path}?${params}`;
  }

  const limit = Number(payload?.query?.limit || payload?.body?.limit || 100);
  const offset = Number(payload?.query?.offset || payload?.body?.offset || 0);

  try {
    const result = await fetch(`${medusaEndpoint}/store${path}`, options);
    const body = await result.json();

    if (body.errors) {
      throw body.errors[0];
    }

    if (path === "/products/search") {
      const nextPage = offset + (body.pagination?.resultsPerPage ?? limit);
      const previousPage = Math.max(offset - (body.pagination?.resultsPerPage ?? limit), 0);
      body.nextPage = body.pagination?.total > nextPage ? nextPage : null;
      body.previousPage = previousPage > 0 ? previousPage : null;
    } else {
      const nextPage = offset + limit;
      const previousPage = Math.max(offset - limit, 0);
      body.nextPage = body.count > nextPage ? nextPage : null;
      body.previousPage = previousPage > 0 ? previousPage : null;
    }

    return {
      status: result.status,
      ok: result.ok,
      body,
    };
  } catch (error: unknown) {
    console.error("error", error);
    throw error;
  }
}

function objectToURLSearchParams(obj: Record<string, unknown>): URLSearchParams {
  const params = new URLSearchParams();

  for (const key in obj) {
    const value = obj[key];
    if (Array.isArray(value)) {
      value.forEach((value: unknown) => {
        params.append(`${key}[]`, String(value));
      });
    } else {
      params.append(key, String(value));
    }
  }

  return params;
}
