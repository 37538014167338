"use client";

import { Customer } from "@medusajs/medusa";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMeCustomer } from "medusa-react";
import React, { createContext, useCallback, useContext } from "react";

import { medusaClient } from "@/lib/config";
import { useStore } from "@/lib/contexts/store-context";

import { useLoadingRouter } from "./loading-context";

interface AccountContext {
  customer?: Omit<Customer, "password_hash">;
  retrievingCustomer: boolean;
  checkSession: () => void;
  refetchCustomer: () => void;
  handleLogout: () => void;
}

const AccountContext = createContext<AccountContext | null>(null);

interface AccountProviderProps {
  children?: React.ReactNode;
}

const handleDeleteSession = () => {
  return medusaClient.auth.deleteSession();
};

export const AccountProvider = ({ children }: AccountProviderProps) => {
  const { resetCart } = useStore();
  const { customer, isFetched, refetch, remove } = useMeCustomer({
    refetchOnWindowFocus: true,
    staleTime: 300 * 1000,
    retry: (failureCount, error) => {
      if ((error as AxiosError).response?.status === 401) {
        return false;
      }
      return failureCount < 1;
    },
  });
  const retrievingCustomer = !isFetched;

  const router = useLoadingRouter();

  const checkSession = useCallback(() => {
    if (!customer && !retrievingCustomer) {
      router.push("/account/login");
    }
  }, [customer, retrievingCustomer, router]);

  const useDeleteSession = useMutation({
    mutationFn: handleDeleteSession,
    mutationKey: ["delete-session"],
  });

  const handleLogout = () => {
    useDeleteSession.mutate(undefined, {
      onSuccess: () => {
        resetCart();
        remove();
        router.push("/");
      },
    });
  };

  return (
    <AccountContext.Provider
      value={{
        customer,
        retrievingCustomer,
        checkSession,
        refetchCustomer: refetch,
        handleLogout,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);

  if (context === null) {
    throw new Error("useAccount must be used within a AccountProvider");
  }
  return context;
};
